// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-embed-js": () => import("./../src/pages/embed.js" /* webpackChunkName: "component---src-pages-embed-js" */),
  "component---src-pages-feedback-js": () => import("./../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-suggest-resource-js": () => import("./../src/pages/suggest-resource.js" /* webpackChunkName: "component---src-pages-suggest-resource-js" */),
  "component---src-templates-page-template-js": () => import("./../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

