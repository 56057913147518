module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/home/pat/Code/city_bureau/chi-covid-resources/src/intl","languages":["en","es","zh","ar","pl","ur","fil","vi","yo","fr","bs","hi","ko"],"defaultLanguage":"en","redirect":true,"redirectComponent":"/home/pat/Code/city_bureau/chi-covid-resources/src/components/redirect.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"pbz7tnn"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-68381272-7"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"COVID Resource Finder","short_name":"COVID Resources","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/icon.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
